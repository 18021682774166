
import menuMixin from "@/mixins/menu"

export default {
  mixins: [menuMixin],

  props: {
    hideMobile: { type: Boolean, default: false },
  },
}
