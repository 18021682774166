// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Jcsi\\+1kG{margin-bottom:2rem;position:relative}._5oQfK7op{display:block;font-size:.81rem;font-weight:500;line-height:1.39}.khz3SQ7x{padding-top:calc(var(--spacing-default)*5)}.yydhU1sH{width:100%}.pEk\\+Wz4U{display:flex;flex-direction:column;font-size:.75rem;font-weight:500;gap:.8em;text-align:center}.pEk\\+Wz4U:not(:last-child){margin-bottom:.75rem}.Ng8v8Dt9{align-items:center;display:flex;justify-content:center}.Ng8v8Dt9>:not(:last-child){margin-right:1.5rem}.mH2t3uW3{--size:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"form": "Jcsi+1kG",
	"link": "_5oQfK7op",
	"footer": "khz3SQ7x",
	"btn": "yydhU1sH",
	"additional": "pEk+Wz4U",
	"oauth": "Ng8v8Dt9",
	"oauthIcon": "mH2t3uW3"
};
module.exports = ___CSS_LOADER_EXPORT___;
