
import apiErrMixin from "@/mixins/api-err"
import { scrollToFirstError } from "@/utils/error"

export default apiErrMixin.extend({
  props: {
    isPage: { type: Boolean, default: false },
  },

  data: () => ({
    model: { username: "", password: "" },
    errors: {} as Record<string, string>,
    isLoading: false,
    resetPath: "/auth/change-password",
    oauthProviders: [
      // "vkontakte",
      // "yandex",
      // "odnoklassniki",
    ],
  }),

  methods: {
    async login () {
      if (this.isLoading) return

      const { validationObserver } = this.$refs
      if (!validationObserver) return
      const isValid = await (validationObserver as any).validate()
      if (!isValid) return scrollToFirstError()

      this.isLoading = true
      try {
        await this.$auth.loginWith("local", { data: this.model })
        if (!this.isPage) this.$emit("close")
      } catch (e) {
        this.apiErr = (e as any)?.response?.data
        await this.$nextTick()
        scrollToFirstError()
      }
      this.isLoading = false
    },
  },
})
