import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=2278db18"
var script = {}
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=2278db18&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeaderLogo: require('/app/components/app/header/AppHeaderLogo.vue').default,AppHeaderMenu: require('/app/components/app/header/AppHeaderMenu.vue').default})
