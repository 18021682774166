
import Vue from "vue"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
// import debounce from "lodash/debounce"

export default Vue.extend({
  props: {
    justReg: { type: Boolean, default: false },
  },

  data: () => ({
    isOpen: false,
  }),

  watch: {
    isOpen (isOpen) {
      const { menu } = this.$refs
      if (menu && isOpen) disableBodyScroll(menu as Element, {
        reserveScrollBarGap: true,
      })
      else clearAllBodyScrollLocks()
    },

    $route: {
      handler () { this.close() },
      deep: true,
    },
  },

  mounted () {
    // window.addEventListener("resize", this.onResize)
    window.addEventListener("keydown", this.onKeydown)
    this.$nuxt.$on("close-menu", this.close)
  },
  beforeDestroy () {
    // window.removeEventListener("resize", this.onResize)
    this.$nuxt.$off("close-menu", this.close)
    clearAllBodyScrollLocks()
    window.removeEventListener("keydown", this.onKeydown)
  },

  methods: {
    close () { this.isOpen = false },
    // onResize: debounce(function () { this.close() }, 100),

    onKeydown (e: KeyboardEvent) {
      if (this.isOpen && e.code === "Escape") this.close()
    },
  },
})
