import fieldWrapperMixin from "./field-wrapper"

export default fieldWrapperMixin.extend({
  inheritAttrs: false,

  props: {
    value: { type: undefined, default: undefined },
    placeholder: { type: String, default: null },

    // vee-validate
    rules: {
      type: [String, Object] as VuePropType<Nullable<VeeValidateRules>>,
      default: null,
    },
    vid: { type: String, default: "" },
  },

  computed: {
    model: {
      get (): any { return this.value },
      set (model: any) { this.$emit("input", model) },
    },

    errorModel: {
      get (): string { return this.error },
      set (errorModel: string) { this.$emit("update:error", errorModel) },
    },
  },

  watch: {
    value () { this.errorModel = "" },
  },
})
