
import Vue from "vue"

export default Vue.extend({
  layout: "auth",
  layoutSettings: {
    titleToken: "pages.auth.login.title",
  },

  middleware: "auth",
  auth: "guest",
})
