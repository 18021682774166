import { render, staticRenderFns } from "./program.vue?vue&type=template&id=b3a68fc2"
import script from "./program.vue?vue&type=script&lang=js"
export * from "./program.vue?vue&type=script&lang=js"
import style0 from "./program.vue?vue&type=style&index=0&id=b3a68fc2&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLayout: require('/app/components/app/AppLayout.vue').default})
