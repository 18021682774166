
import Vue from "vue"
import { VueMaskDirective } from "v-mask"
import fieldMixin from "@/mixins/field"

Vue.directive("mask", VueMaskDirective)

export default fieldMixin.extend({
  props: {
    mask: { type: String, default: null },
  },

  computed: {
    adaptedPlaceholder (): string {
      const { placeholder, label, $attrs: { type } } = this
      if (placeholder) return placeholder
      if (type === "email") return "user@example.com"
      if (type === "tel") return this.$i18n.locale === "ru"
        ? "+7 999 999-99-99"
        : "+1 111 111-11-11"
      if (type === "url") return "https://example.com"
      if (label) return label
      return this.$t("form.enter") as string
    },
  },

  created () {
    this.onChange()
  },

  methods: {
    onChange () {
      const { model, $attrs: { type } } = this
      if (type === "url") {
        if (model && typeof model === "string" && !model.startsWith("http"))
          this.model = `http://${model}`
      } else if (type === "number") {
        if (typeof model !== "number") {
          const numberModel = Number(model)
          this.model = Number.isNaN(numberModel) ? undefined : numberModel
        }
      }
    },
  },
})
