import { wrapGql } from "../_"

export default (a: NuxtAxiosInstance) => ({
  markUserVisit: (user: string) => wrapGql(
    a.$post("/graphql", {
      query: `
        mutation ($user: UUID!) {
          markUserVisit (user: $user)
        }
      `,
      variables: { user },
    }),
    "markUserVisit",
  ),
})
