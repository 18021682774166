
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState("app", ["event"]),

    eventLogoSrc () { return this.event?.theme?.logo?.url || "" },
  },
}
